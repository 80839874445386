@font-face {
  font-family: 'Gazpacho';
  src: url('./assets/fonts/Woff/Gazpacho/Gazpacho-Black.woff') format('woff'),
  url('./assets/fonts/Woff/Gazpacho/Gazpacho-Bold.woff') format('woff'),
  url('./assets/fonts/Woff/Gazpacho/Gazpacho-Light.woff') format('woff'),
  url('./assets/fonts/Woff/Gazpacho/Gazpacho-Medium.woff') format('woff'),
  url('./assets/fonts/Woff/Gazpacho/Gazpacho-Regular.woff') format('woff'),
  url('./assets/fonts/Woff/Gazpacho/Gazpacho-Heavy.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@import './fonts.scss';
@import 'react-toastify/dist/ReactToastify.css';

@font-face {
  font-family: 'Lufga';
  font-weight: 100; // Thin
  font-style: normal;
  src: url('./assets/fonts/Woff/Lufga/Lufga-Thin.woff') format('woff');
}

// @font-face {
//   font-family: 'Lufga';
//   src: url('./assets/fonts/Woff/Lufga/Lufga-Regular.woff') format('woff'),
//     url('./assets/fonts/Woff/Lufga/Lufga-Bold.woff') format('woff'),
//     url('./assets/fonts/Woff/Lufga/Lufga-ExtraLight.woff') format('woff'),
//     url('./assets/fonts/Woff/Lufga/Lufga-Italic.woff') format('woff'),
//     url('./assets/fonts/Woff/Lufga/Lufga-Light.woff') format('woff'),
//     url('./assets/fonts/Woff/Lufga/Lufga-Regular.woff') format('woff'),
//     url('./assets/fonts/Woff/Lufga/Lufga-SemiBold.woff') format('woff'),
//     url('./assets/fonts/Woff/Lufga/Lufga-Thin.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
// }

body {
  font-family: 'Lufga', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

// Grommet override

.StyledGrommet-sc-19lkkz7-0 {
  background-image: url(./assets/images/background-home.jpg) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
}

.StyledTextInput-sc-1x30a0s-0 {
  border: 1px solid #ccc !important;
  border-radius: 8px !important;
  background-color: white !important;
}

.btn-text-left {
  justify-content: left !important;
}

.btn.btn-pink {
  background-color: #e727c7;
  border-color: #e727c7;
}

.input {
  font-size: 0.875rem !important;
}

.Toastify__toast {
  border-radius: 10px !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #1B5E20;
}

.nowrap {
  white-space: nowrap !important;
}

.txtEllipsis {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.max2lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.imgEmpty {
  height: 96px;
  width: 96px;
  align-self: center;
}

.imgLoginSide {
  background-image: url(./assets/images/login.jpg) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
}

.flag-table {
  max-height: 20px;
  width: auto !important;
}

.logoPlatform32 {
  width: 32px !important;
  height: 32px !important;
}

.imgCardInfluencer {
  height: 150px !important;
  object-fit: cover !important;
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.imgContain {
  object-fit: contain !important;
}

.imgCover {
  object-fit: cover !important;
}


.meter-caption {
  display: flex;
  justify-content: space-between;

  .meter-caption-left {
    display: flex;
    gap: .5rem;
    align-items: center;
  }

  .caption-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;

    &--MALE {
      background-color: #49a1f8;
    }

    &--FEMALE {
      background-color: #db56a4;
    }
  }

  .caption-label {

  }

  .caption-value {

  }
}


.bg-yellow {
  background-color: #ffedaf !important;
}

.position-relative {
  position: relative;
}


.blur-bg {
  position: relative;
  &:after {
    position: absolute;
    display: block;
    content: "";
    height: 100%;
    width: 104%;
    left: -10px;
    overflow: hidden;
    background: rgba(#fff, .8);
    backdrop-filter: blur(8px);
  }
}

.blur-cta {
  white-space: break-spaces !important;
  text-align: center;
  position: absolute;
  top: 200px;
  left: 30px;
}

.place-bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}


