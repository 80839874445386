.card-header {
  height: 15rem;
  width: 100%;
  background-size: cover;
  background-size: cover;
  border-top-right-radius: var(--rounded-box, 1rem);
  border-top-left-radius: var(--rounded-box, 1rem);

  padding: 1rem;
  position: relative;

  .overlay {
    border-top-right-radius: var(--rounded-box, 1rem);
    border-top-left-radius: var(--rounded-box, 1rem);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}