.influencers {
  padding: 1rem;
  .influencer-ethic {
    cursor: pointer;
    position: relative;
    width: 250px;
    height: 250px;
    border-radius: 5px;

    box-shadow: 2px 5px 5px rgba(#000, .3);

    overflow: hidden;
    .img-container {
      img {
        width: 250px;
        height: 250px;
        border-radius: 5px;
      }
    }

    &:hover .text-container {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .text-container {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);

      width: 80%;
      height: 80%;
      background: rgba(#000, .6);
      border-radius: 5px;
      color: #fff;

      display: flex;
      justify-content: center;
      align-items: center;

      transition: ease-out .3s;
    }

    .network-img-container {
      position: absolute;

      top: 0;
      padding: .3rem;
      background: white;
      border-bottom-right-radius: 10px;
      z-index: 9;
    }
  }
}