@font-face {
  font-family: 'Lufga';
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/Woff/Lufga/Lufga-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Lufga';
  font-weight: bold;
  font-style: normal;
  src: url('./assets/fonts/Woff/Lufga/Lufga-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Lufga';
  font-weight: 200; // Valeur pour la variante ExtraLight
  font-style: normal;
  src: url('./assets/fonts/Woff/Lufga/Lufga-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: 'Lufga';
  font-weight: normal;
  font-style: italic;
  src: url('./assets/fonts/Woff/Lufga/Lufga-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Lufga';
  font-weight: 300; // Light
  font-style: normal;
  src: url('./assets/fonts/Woff/Lufga/Lufga-Light.woff') format('woff');
}

@font-face {
  font-family: 'Lufga';
  font-weight: 600; // SemiBold
  font-style: normal;
  src: url('./assets/fonts/Woff/Lufga/Lufga-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Gazpacho';
  font-weight: 900; // Black
  font-style: normal;
  src: url('./assets/fonts/Woff/Gazpacho/Gazpacho-Black.woff') format('woff');
}

@font-face {
  font-family: 'Gazpacho';
  font-weight: bold;
  font-style: normal;
  src: url('./assets/fonts/Woff/Gazpacho/Gazpacho-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Gazpacho';
  font-weight: 300; // Light
  font-style: normal;
  src: url('./assets/fonts/Woff/Gazpacho/Gazpacho-Light.woff') format('woff');
}

@font-face {
  font-family: 'Gazpacho';
  font-weight: 500; // Medium
  font-style: normal;
  src: url('./assets/fonts/Woff/Gazpacho/Gazpacho-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Gazpacho';
  font-weight: normal;
  font-style: normal; // Regular
  src: url('./assets/fonts/Woff/Gazpacho/Gazpacho-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Gazpacho';
  font-weight: 800; // Heavy
  font-style: normal;
  src: url('./assets/fonts/Woff/Gazpacho/Gazpacho-Heavy.woff') format('woff');
}
